import AbstractComponent from './abstractComponent';

export default class AgencyMap extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            const labelBtnHover = this.jQuerySection.find('.mapAgency__button');
            const labelBtnInfo = this.jQuerySection.find(
                '.mapAgency__button--hover'
            );
            const labelClkBtnInfo = this.jQuerySection.find(
                '.mapAgency__button--click'
            );
            const infoBoxes = this.jQuerySection.find('.mapAgency__info');

            labelBtnHover.on('mouseenter focus', function () {
                labelBtnHover.not(this).addClass('mapAgency__button--trim');
            });

            labelBtnInfo.on('mouseenter focus', function () {
                infoBoxes.removeClass('mapAgency__info--active');
                jQuery(`.mapAgency__info--${this.dataset.id}`).addClass(
                    'mapAgency__info--active'
                );
            });

            labelBtnHover.on('mouseleave focus', () => {
                labelBtnHover.removeClass('mapAgency__button--trim');
            });

            labelClkBtnInfo.on('click focus', function () {
                infoBoxes.removeClass('mapAgency__info--active');
                jQuery(`.mapAgency__info--${this.dataset.id}`).addClass(
                    'mapAgency__info--active'
                );
            });
        }
    }
}
