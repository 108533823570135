import AbstractComponent from './abstractComponent';

export default class HeaderU extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        function trainingSearchRender(result) {
            const trainingSearchResult = jQuery('.trainingSearchResult');
            trainingSearchResult.empty();

            if (result.items !== undefined && result.items.length > 0) {
                const resultUi = jQuery('<ul/>');
                const { items } = result;
                for (let i = 0; i < items.length; i += 1) {
                    const resultCategory = jQuery('<li/>', {
                        class: 'trainingAutocompleteCategory',
                    });
                    resultCategory.append(
                        `<a href='${items[i].category_link}'>${items[i].category_name}</a>`
                    );
                    resultUi.append(resultCategory);

                    const resultLabel = jQuery('<li/>', {
                        class: 'trainingAutocompleteLabel',
                    });
                    resultLabel.append(
                        `<a href='${items[i].training_link}'>${items[i].training_title}</a>`
                    );
                    resultUi.append(resultLabel);
                }
                trainingSearchResult.append(resultUi);
                trainingSearchResult.append(
                    `<div class='trainingSearchResultMore'><a class='trainingSearchResult__link' href='${
                        result.more_link
                    }'>${Translator.trans('text.more')}</a></div>`
                );
            }
        }

        if (this.exists) {
            $(() => {
                $('.header__menu__button').click(() => {
                    $('.mainHeader').toggleClass('mainHeader--active');
                    $('body').toggleClass('overflow-hidden');
                    $('.nav-wrapper').show();
                });

                $('.nav-header__button').click(() => {
                    $('.mainHeader').toggleClass('mainHeader--active');
                    $('body').toggleClass('overflow-hidden');
                    $('.nav-wrapper').hide();
                });

                const cardList = $('div.cardListing__wrapper');
                const forWho = $('#for-who');
                const mediText = $('div.wp-block-media-text');
                const threeColumnGridFirst = $('.threeColumnGrid').eq(0);
                const threeColumnGridSecond = $('.threeColumnGrid').eq(1);
                const contactForm = $('div.contactForm__U');
                let trainingActionBar = false;

                let screenTop = 140;
                if (window.innerWidth < 991) {
                    screenTop = 100;
                }

                $('button.headerblock__left__arrow').click(() => {
                    $('html, body').animate(
                        {
                            scrollTop: $('div.cardListing__wrapper').offset()
                                .top,
                        },
                        1000
                    );
                });

                if (forWho !== 'undefined' && forWho.length > 0) {
                    $('button.whoBtn').show();
                    $('button.whoBtn').click(() => {
                        $('html, body').animate(
                            {
                                scrollTop:
                                    $('#for-who').offset().top - screenTop,
                            },
                            1000
                        );
                    });
                    trainingActionBar = true;
                }

                if (mediText !== 'undefined' && mediText.length > 0) {
                    $('button.programBtn').show();
                    $('button.programBtn').click(() => {
                        $('html, body').animate(
                            {
                                scrollTop:
                                    $('div.wp-block-media-text').offset().top -
                                    screenTop,
                            },
                            1000
                        );
                    });
                    trainingActionBar = true;
                }

                if (contactForm !== 'undefined' && contactForm.length > 0) {
                    $('button.contactBtn').css('visibility', 'visible');
                    $('button.contactBtn').click(() => {
                        $('html, body').animate(
                            {
                                scrollTop:
                                    $('div.contactForm__U').offset().top -
                                    screenTop,
                            },
                            1000
                        );
                    });
                    trainingActionBar = true;
                }

                if (cardList !== 'undefined' && cardList.length > 0) {
                    $('button.pointsBtn').show();
                    $('button.pointsBtn').click(() => {
                        $('html, body').animate(
                            {
                                scrollTop:
                                    $('div.cardListing__wrapper').offset().top -
                                    screenTop,
                            },
                            1000
                        );
                    });
                    trainingActionBar = true;
                }

                // goals
                if (
                    threeColumnGridFirst !== 'undefined' &&
                    threeColumnGridFirst.length > 0
                ) {
                    $('button.goalsBtn').show();
                    $('button.goalsBtn').click(() => {
                        $('html, body').animate(
                            {
                                scrollTop:
                                    threeColumnGridFirst.offset().top -
                                    screenTop,
                            },
                            1000
                        );
                    });
                    trainingActionBar = true;
                }

                // ped
                if (
                    threeColumnGridSecond !== 'undefined' &&
                    threeColumnGridSecond.length > 0
                ) {
                    $('button.pedBtn').show();
                    $('button.pedBtn').click(() => {
                        $('html, body').animate(
                            {
                                scrollTop:
                                    threeColumnGridSecond.offset().top -
                                    screenTop,
                            },
                            1000
                        );
                    });
                    trainingActionBar = true;
                }

                if (trainingActionBar) {
                    $('.actionBarWrapper').show();
                }
            });

            if (window.innerWidth < 991) {
                let lastScrollTop = 0;
                $(window).on('scroll', function () {
                    if ($(this).scrollTop() < lastScrollTop) {
                        $('.actionBarWrapper').css('top', '6.9rem');
                    } else {
                        $('.actionBarWrapper').css('top', '0');
                    }
                    lastScrollTop = $(this).scrollTop();
                });
            }

            $(window).scroll(function () {
                if ($(this).scrollTop() > 1000) {
                    $('.button__scroll').fadeIn();
                } else {
                    $('.button__scroll').fadeOut();
                }
            });

            $('.button__scroll').on('click', () => {
                $('html, body').animate({ scrollTop: 0 }, '300');
            });

            $(window).scroll(() => {
                $('.trainingSearchResult').empty().hide();
                $('#training-search-box').val('');
                $('.trainingSearchForm__input').hide();
                $('#training-search-icon').show();
            });

            $(window).on('click', (e) => {
                const targetElm = $(e.target);
                if (
                    !(
                        e.target.id === 'trainingSearch' ||
                        targetElm.parents('#trainingSearch').length > 0
                    )
                ) {
                    $('.trainingSearchResult').empty().hide();
                    $('#training-search-box').val('');
                    $('.trainingSearchForm__input').hide();
                    $('#training-search-icon').show();
                }
            });

            $('#trainingSearchForm__searchClear').on('click', () => {
                $('#training-search-box').val('');
                $('.trainingSearchResult').empty();
            });

            $('#training-search-icon').on('click', function () {
                $('.trainingSearchForm__input').show();
                $('.trainingSearchResult').show();
                $(this).hide();
            });

            const cache = {};

            jQuery('#training-search-box').keyup(function () {
                jQuery('.trainingSearchResult').empty();
                const searchVal = jQuery(this).val();
                if (searchVal in cache) {
                    trainingSearchRender(cache[searchVal]);
                    return;
                }
                jQuery.ajax({
                    url: '/api/v1/training/autocomplete',
                    data: { term: searchVal },
                    dataType: 'json',
                    success(result) {
                        cache[searchVal] = result;
                        trainingSearchRender(result);
                    },
                });
            });

            if (this.exists) {
                $('#moreTraining').click(function () {
                    const moreButton = $(this);
                    const waitMessage = $('.trainingList__wait');
                    const nextPage = moreButton.data('next-page');
                    const search = $('#training-search').val();
                    const choice = $('#training-choice').val();
                    const path = moreButton.data('path');
                    const limit = 9;
                    $.ajax({
                        url: path,
                        data: {
                            search,
                            page: nextPage,
                            limit,
                            choice,
                        },
                        dataType: 'json',
                        beforeSend() {
                            moreButton.hide();
                            waitMessage.show();
                        },
                        success(result) {
                            waitMessage.hide();
                            if (result) {
                                $('#trainingList_Content').append(result.html);
                                moreButton.data('next-page', result.page);
                                if (result.nextCount > 0) {
                                    moreButton.show();
                                }
                            }
                        },
                    });
                });
            }
        }
    }
}
