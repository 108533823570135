import AbstractComponent from './abstractComponent';

export default class sliderTestimonialQuotes extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            $('.sliderTestimonialQuotes').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                arrows: true,
                dots: false,
                infinite: true,
                adaptiveHeight: true,
            });
        }
    }
}
