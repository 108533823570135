import AbstractComponent from './abstractComponent';

export default class TestimonialsTimeline extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            const _this = this;

            $('.testimonialsTimeline__list')
                .filter(function () {
                    return (
                        $(this).offset().top > jQuery(_this.$window).height()
                    );
                })
                .hide();
        }
    }

    addEvents() {
        const _this = this;
        const itemsPerScroll = 5;

        jQuery(_this.$window).scroll(() => {
            if (
                jQuery(_this.$document).scrollTop() + window.innerHeight >
                $('.mainFooter').offset().top
            ) {
                $(
                    `.testimonialsTimeline__list:hidden:lt(${itemsPerScroll})`
                ).show();
            }
        });
    }
}
