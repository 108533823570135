import AbstractComponent from './abstractComponent';

export default class TestimonialJobList extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            $('.testimonialSliderContent__list').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                dots: false,
                infinite: true,
                adaptiveHeight: true,
                asNavFor: '.testimonialSliderNav__list',
            });
            $('.testimonialSliderNav__list').slick({
                vertical: true,
                verticalSwiping: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                asNavFor: '.testimonialSliderContent__list',
                infinite: true,
                centerMode: false,
                focusOnSelect: true,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 900,
                        settings: {
                            vertical: false,
                            slidesToShow: 3,
                        },
                    },
                ],
            });
        }
    }
}
