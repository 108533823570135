import AbstractComponent from './abstractComponent';
import 'slick-carousel';

export default class Footer extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            this.slide = this.jQuerySection.find('.footerContacts__main');
            this.slideNav = this.jQuerySection.find('.footerContacts__nav');
            this.btnPrev = this.jQuerySection.find('.slickNav__prev');
            this.btnNext = this.jQuerySection.find('.slickNav__next');
            this.windowWidthAfterResize = 0;
            this.resizeTimeout = undefined;

            this.slickSettings = {
                dots: false,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                touchMove: false,
                draggable: false,
                infinite: true,
                fade: true,
                asNavFor: this.slideNav,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            asNavFor: false,
                            arrows: true,
                            prevArrow:
                                '<button type="button" class="slick-prev"><span class="iconArrowLeft"></span></button>',
                            nextArrow:
                                '<button type="button" class="slick-next"><span class="iconArrowRight"></span></button>',
                        },
                    },
                ],
            };

            this.slickNavSettings = {
                dots: false,
                arrows: true,
                slidesToShow: 4,
                draggable: false,
                slidesToScroll: 1,
                centerMode: true,
                touchMove: false,
                vertical: true,
                infinite: true,
                focusOnSelect: true,
                asNavFor: this.slide,
                prevArrow:
                    '<button type="button" class="slick-prev"><span class="iconArrowTop"></span></button>',
                nextArrow:
                    '<button type="button" class="slick-next"><span class="iconArrowDown"></span></button>',
                responsive: [
                    {
                        breakpoint: 600,
                        settings: 'unslick',
                    },
                ],
            };

            this.slide.not('.slick-initialized').slick(this.slickSettings);

            if (
                this.windowDimensions.width <= 600 ||
                this.windowWidthAfterResize <= 600
            ) {
                jQuery(window).resize(() => {
                    clearTimeout(this.resizeTimeout);
                    this.windowWidthAfterResize = this.windowDimensions.width;
                    this.resizeTimeout = setTimeout(() => {
                        if (this.windowDimensions.width >= 599) {
                            this.slideNav
                                .not('.slick-initialized')
                                .slick(this.slickNavSettings);
                        }
                    }, 250);
                });
            }

            this.slideNav
                .not('.slick-initialized')
                .slick(this.slickNavSettings);

            $('.disable-coockie-link a').click((event) => {
                event.preventDefault();
            });
        }
    }
}
