import AbstractComponent from './abstractComponent';

export default class ContentWrapperDefault extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) { 
            const timerStart = Date.now();
            window.onload = function () {
                const timeLoaded = Date.now() - timerStart;
                if (timeLoaded < 500) {
                    setTimeout(() => {
                        jQuery(document.body).addClass('pageReady');
                    }, 500);
                } else {
                    jQuery(document.body).addClass('pageReady');
                }
            };
        }
    }
}
