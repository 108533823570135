import AbstractComponent from './abstractComponent';
import 'jquery-ui/themes/base/autocomplete.css';
import 'jquery-ui/ui/widgets/autocomplete';

export default class PublicationsArchive extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            const cache = {};
            jQuery('#news-search').autocomplete({
                minLength: 2,
                source(request, response) {
                    const { term } = request;
                    if (term in cache) {
                        response(cache[term]);
                        return;
                    }
                    request.type = jQuery('#news-type').val();
                    jQuery.getJSON(
                        '/api/v1/publications/autocomplete',
                        request,
                        (data) => {
                            cache[term] = data;
                            response(data);
                        }
                    );
                },
            });

            $('#morePublications').click(function () {
                const moreButton = $(this);
                const waitMessage = $('.newsSearch__wait');
                const nextPage = moreButton.data('next-page');
                const search = $('#news-search').val();
                const type = $('#news-type').val();
                const path = moreButton.data('path');
                const limit = 6;
                $.ajax({
                    url: path,
                    data: {
                        type,
                        search,
                        page: nextPage,
                        limit,
                    },
                    dataType: 'json',
                    beforeSend () {
                        moreButton.hide();
                        waitMessage.show();
                    },
                    success (result) {
                        waitMessage.hide();
                        if (result) {
                            $('.newsArchiveArticles__list').append(result.html);
                            moreButton.data('next-page', result.page);
                            if (result.nextCount > 0) {
                                moreButton.show();
                            }
                        }
                    },
                });
            });

            /* $(function () {
                $('#newsArchiveArticles__readMore').click(function () {
                    $('.newsArchiveArticles__items:hidden').slice(0, 6).show();
                    if (
                        $('.newsArchiveArticles__items').length ==
                        $('.newsArchiveArticles__items:visible').length
                    ) {
                        $('#newsArchiveArticles__readMore').hide();
                    }
                });
                if ($('.newsArchiveArticles__items').length > 6) {
                    $('#newsArchiveArticles__readMore').show();
                }
            }); */

            const $sticky = $('.featuredArticle');
            const $stickyrStopper = $('.mainFooter');
            $(window).scroll(() => {
                if ($(window).scrollTop() > 400) {
                    $sticky.css('position', 'fixed');
                    $sticky.css('top', '0');
                } else if ($(window).scrollTop() <= 400) {
                    $sticky.css('position', '');
                    $sticky.css('top', '');
                }
                if (
                    $sticky.offset().top + $sticky.outerHeight() >
                    $stickyrStopper.offset().top
                ) {
                    $sticky.css(
                        'top',
                        -(
                            $sticky.offset().top +
                            $sticky.outerHeight() -
                            $stickyrStopper.offset().top
                        )
                    );
                }
            });
        }
    }
}
