import AbstractComponent from './abstractComponent';
import 'slick-carousel';

export default class textQuoteSlider extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            $('.textQuoteSlider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                arrows: true,
                dots: false,
                infinite: false,
                adaptiveHeight: true,
            });
        }
    }
}
