import AbstractComponent from './abstractComponent';
import 'animsition/dist/js/animsition';

export default class Animsition extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            $('.animsition').animsition({
                outClass: 'fade-out-right-lg',
                outDuration: 800,
                linkElement: '.animsition-link',
                loading: false,
                loadingParentElement: 'body',
                loadingClass: 'animsition-loading',
                loadingInner: '',
                timeout: false,
                timeoutCountdown: 5000,
                onLoadEvent: true,
                browser: ['animation-duration', '-webkit-animation-duration'],
                overlay: false,
                overlayClass: 'animsition-overlay-slide',
                overlayParentElement: 'body',
                transition(url) {
                    window.location.href = url;
                },
            });
        }
    }
}
