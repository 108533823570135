import AbstractComponent from './abstractComponent';

export default class UseCaseLatest extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            $('#moreUseCase').click(function () {
                const moreButton = $(this);
                const waitMessage = $('.useCaseLatest__wait');
                const nextPage = moreButton.data('next-page');
                const search = $('#use-case-search').val();
                const expertise = $('#use-case-expertise').val();
                const sector = $('#use-case-sector').val();
                const path = moreButton.data('path');
                const limit = 9;
                $.ajax({
                    url: path,
                    data: {
                        search,
                        expertise,
                        sector,
                        page: nextPage,
                        limit,
                    },
                    dataType: 'json',
                    beforeSend () {
                        moreButton.hide();
                        waitMessage.show();
                    },
                    success (result) {
                        waitMessage.hide();
                        if (result) {
                            $('#useCaseLatest__list').append(result.html);
                            moreButton.data('next-page', result.page);
                            if (result.nextCount > 0) {
                                moreButton.show();
                            }
                        }
                    },
                });
            });
        }
    }
}
