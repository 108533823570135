import AbstractComponent from './abstractComponent';

export default class WebinarRegistrationForm extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);
        function enableSubmitButton(inputs, submit) {
            let index;
            let emptyFlag = false;
            for (index = 0; index < inputs.length; index += 1) {
                const element = $(inputs[index]);
                if (!element.val()) {
                    emptyFlag = true;
                }
            }

            submit.prop('disabled', emptyFlag);
        }

        $('.introLanding__btn').click(() => {
            $('html, body').animate(
                {
                    scrollTop: $('div.webinarRegistrationForm').offset().top,
                },
                1000
            );
        });

        if ($('.webinarRegistrationFormSubmitted').length) {
            $('html, body').animate(
                {
                    scrollTop: $('.webinarRegistrationFormSubmitted').offset()
                        .top,
                },
                1000
            );
        }

        if (this.exists) {
            const form = this.jQuerySection.find(
                '.webinarRegistrationForm__form'
            );
            const firstName = this.jQuerySection.find(
                '.webinarRegistrationForm__first_name'
            );
            const lastName = this.jQuerySection.find(
                '.webinarRegistrationForm__last_name'
            );
            const email = this.jQuerySection.find(
                '.webinarRegistrationForm__email'
            );
            const phone = this.jQuerySection.find(
                '.webinarRegistrationForm__phone'
            );
            const company = this.jQuerySection.find(
                '.webinarRegistrationForm__company'
            );
            const designation = this.jQuerySection.find(
                '.webinarRegistrationForm__designation'
            );
            const consent = this.jQuerySection.find(
                '.webinarRegistrationForm__consent1'
            );
            const field = this.jQuerySection.find(
                '.webinarRegistrationForm__input'
            );
            const submit = this.jQuerySection.find('.btn-contact');
            const inputs = this.jQuerySection.find(
                '.webinarRegistrationForm__fields'
            );
            const error = this.jQuerySection.find('.form__Error');
            const phonePattern =
                /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
            const emailPattern = /^([\w-.]+@([\w-]+\.)+[\w-]{2,20})?$/;

            inputs.keyup(() => {
                enableSubmitButton(inputs, submit);
            });

            form.submit(() => {
                let flag = true;
                error.html('');

                if (!email.val()) {
                    email
                        .siblings('.form__Error')
                        .html(Translator.trans('form.error.email'));
                    flag = false;
                } else if (!emailPattern.test(email.val())) {
                    email
                        .siblings('.form__Error')
                        .html(Translator.trans('form.error.email_invalid'));
                    flag = false;
                }

                if (phone.val() && !phonePattern.test(phone.val())) {
                    phone
                        .siblings('.form__Error')
                        .html(Translator.trans('form.error.phone_invalid'));
                    flag = false;
                }

                if (!consent.length) {
                    if (!firstName.val()) {
                        firstName
                            .siblings('.form__Error')
                            .html(Translator.trans('form.error.first_name'));
                        flag = false;
                    }
                    if (!lastName.val()) {
                        lastName
                            .siblings('.form__Error')
                            .html(Translator.trans('form.error.last_name'));
                        flag = false;
                    }
                    if (!company.val()) {
                        company
                            .siblings('.form__Error')
                            .html(
                                Translator.trans(
                                    'form.error.invalid_contact_company'
                                )
                            );
                        flag = false;
                    }
                    if (!designation.val()) {
                        company
                            .siblings('.form__Error')
                            .html(Translator.trans('form.error.designation'));
                        flag = false;
                    }
                }

                return flag;
            });

            field.focus(function () {
                jQuery(this).siblings('.form__Error').html('');
            });

            if ($('.webinarRegistrationForm__info__message').length > 0) {
                setTimeout(() => {
                    $('.webinarRegistrationForm__info__message').fadeOut();
                }, 3000);
            }

            $('.webinarRegistrationForm__input').focusout(function () {
                if ($(this).val() !== '') {
                    $(this).addClass('has-content');
                } else {
                    $(this).removeClass('has-content');
                }
            });
        }
    }
}
