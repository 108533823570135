import AbstractComponent from './abstractComponent';
import 'jquery-ui/themes/base/autocomplete.css';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/themes/base/selectmenu.css';
import 'jquery-ui/ui/widgets/selectmenu';

export default class UseCaseSearch extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);
        function useCaseFilter(type, filterVal) {
            let location = `${window.location.pathname}?search=${$(
                '#use-case-search'
            ).val()}`;

            if (!filterVal) {
                gtmEvent({
                    event: 'click',
                    eventCategory: 'filter',
                    eventAction: 'usecase_filter',
                    eventLabel: encodeURIComponent(filterVal),
                });
            }

            if (type === 'expertise') {
                location += `&expertise=${filterVal}&sector=${$(
                    '#use-case-sector'
                ).val()}`;
            }

            if (type === 'sector') {
                location += `&expertise=${$(
                    '#use-case-expertise'
                ).val()}&sector=${filterVal}`;
            }

            window.location = location;
        }
        if (this.exists) {
            const cache = {};
            jQuery('#use-case-search').autocomplete({
                minLength: 2,
                source(request, response) {
                    const { term } = request;
                    if (term in cache) {
                        response(cache[term]);
                        return;
                    }
                    jQuery.getJSON(
                        '/api/v1/use-case/autocomplete',
                        request,
                        (data) => {
                            cache[term] = data;
                            response(data);
                        }
                    );
                },
            });

            this.jQuerySection
                .find('.useCase__selectWrapper_expertiseFilter')
                // eslint-disable-next-line no-unused-vars
                .on('selectmenuselect', function (event, ui) {
                    $(this)
                        .parent()
                        .find('.ui-selectmenu-button')
                        .addClass('ui-selectmenu-bold');
                    useCaseFilter(
                        'expertise',
                        $('#useCase__selectWrapper_expertiseFilter').val()
                    );
                });

            this.jQuerySection
                .find('.useCase__selectWrapper_sectorFilter')
                // eslint-disable-next-line no-unused-vars
                .on('selectmenuselect', function (event, ui) {
                    $(this)
                        .parent()
                        .find('.ui-selectmenu-button')
                        .addClass('ui-selectmenu-bold');
                    useCaseFilter(
                        'sector',
                        $('#useCase__selectWrapper_sectorFilter').val()
                    );
                });

            this.jQuerySection
                .find('.useCase__selectWrapper_expertiseFilter')
                .selectmenu({
                    open(/* event, ui */) {
                        $(
                            '#useCase__selectWrapper_expertiseFilter option[value=""]'
                        ).text(
                            Translator.trans(
                                'use_case.expertise_placeholder_change'
                            )
                        );
                        $('#useCase__selectWrapper_expertiseFilter').selectmenu(
                            'refresh'
                        );
                    },
                    close(/* event, ui */) {
                        $(
                            '#useCase__selectWrapper_expertiseFilter option[value=""]'
                        ).text(
                            Translator.trans('use_case.expertise_placeholder')
                        );
                        $('#useCase__selectWrapper_expertiseFilter').selectmenu(
                            'refresh'
                        );
                    },
                });

            this.jQuerySection
                .find('.useCase__selectWrapper_sectorFilter')
                .selectmenu({
                    open(/* event, ui */) {
                        $(
                            '#useCase__selectWrapper_sectorFilter option[value=""]'
                        ).text(
                            Translator.trans(
                                'use_case.sector_placeholder_change'
                            )
                        );
                        $('#useCase__selectWrapper_sectorFilter').selectmenu(
                            'refresh'
                        );
                    },
                    close(/* event, ui */) {
                        $(
                            '#useCase__selectWrapper_sectorFilter option[value=""]'
                        ).text(Translator.trans('use_case.sector_placeholder'));
                        $('#useCase__selectWrapper_sectorFilter').selectmenu(
                            'refresh'
                        );
                    },
                });

            $('#searchClear').click(() => {
                window.location = window.location.pathname;
            });
        }
    }
}
