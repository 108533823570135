// TOOD: use https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
// or at least jQuery Waypoints instead of the no-framework version.
import 'waypoints/lib/noframework.waypoints.min';
import counterUp from 'counterup2';
import AbstractComponent from './abstractComponent';

export default class NumberBlock extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            if ($('.numberBlock__1').length) {
                document.querySelectorAll('.numberBlock__1').forEach((elem) => {
                    // eslint-disable-next-line no-undef,no-new
                    new Waypoint({
                        element: elem,
                        handler() {
                            counterUp(elem, {
                                duration: 1000,
                                delay: 16,
                            });
                            this.destroy();
                        },
                        offset: '75%',
                    });
                });
            }

            (function ($) {
                // eslint-disable-next-line no-param-reassign
                $.fn.visible = function (partial) {
                    const $t = $(this);
                    const $w = $(window);
                    const viewTop = $w.scrollTop();
                    const viewBottom = viewTop + $w.height();
                    const _top = $t.offset().top;
                    const _bottom = _top + $t.height();
                    const compareTop = partial === true ? _bottom : _top;
                    const compareBottom = partial === true ? _top : _bottom;

                    return compareBottom <= viewBottom && compareTop >= viewTop;
                };
            })(jQuery);

            const win = $(window);
            const allMods = $('.numberBlock__2');

            allMods.each((i, el) => {
                const item = $(el);
                if (item.visible(true)) {
                    item.addClass('already-visible');
                }
            });

            win.scroll((/* event */) => {
                allMods.each((i, el) => {
                    const item = $(el);
                    if (item.visible(true)) {
                        item.addClass('come-in');
                    }
                });
            });
        }
    }
}
