import AbstractComponent from './abstractComponent';

export default class GtmEvents extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);
        let gtmEventObj = {
            event: 'event',
            eventCategory: '',
            eventAction: '',
            eventLabel: '',
        };

        const eventCategory = {
            apply: 'apply',
            career: 'career',
            contact: 'contact',
            footer: 'footer',
            header: 'header',
            navigation: 'navigation',
            news: 'news',
            publication: 'publication',
            social: 'social',
            search: 'search',
            services: 'services',
            use_case: 'use cases',
            formation: 'formation',
            player: 'player',
            about_us: 'about_us',
        };

        function setGtmEvent() {
            const gtmResult =
                Object.values(gtmEventObj).filter((item) => item === '')
                    .length === 0;
            if (gtmResult) {
                /* eslint-disable */
                gtmEvent(gtmEventObj);
            }
            gtmEventObj = {
                event: 'click',
                eventCategory: '',
                eventAction: '',
                eventLabel: '',
            };
        }

        function findSocialMedia(obj) {
            let media = '';
            if (
                obj.parent().hasClass('iconTwitter') ||
                obj.data('title') === 'Twitter' ||
                obj.hasClass('dpsp-twitter')
            ) {
                media = 'twitter';
            } else if (
                obj.parent().hasClass('iconInstagram') ||
                obj.data('title') === 'Instagram' ||
                obj.hasClass('dpsp-instagram')
            ) {
                media = 'instagram';
            } else if (
                obj.parent().hasClass('iconFacebook') ||
                obj.data('title') === 'Facebook' ||
                obj.hasClass('dpsp-facebook')
            ) {
                media = 'facebook';
            } else if (
                obj.parent().hasClass('iconLinkedIn') ||
                obj.data('title') === 'LinkedIn' ||
                obj.hasClass('dpsp-linkedin')
            ) {
                media = 'linkedin';
            }
            return media;
        }

        $(document).ready(function () {
            let scrollFlag = 0;
            $(window).scroll(function () {
                gtmEventObj.eventCategory = eventCategory.navigation;
                gtmEventObj.eventAction = 'scroll';
                const scrollTop = $(window).scrollTop();
                const docHeight = $(document).height();
                const winHeight = $(window).height();
                const scrollPercent = scrollTop / (docHeight - winHeight);
                const scrollPercentRounded = Math.round(scrollPercent * 100);
                if (
                    scrollPercentRounded >= 50 &&
                    scrollPercentRounded <= 65 &&
                    scrollFlag !== 50
                ) {
                    scrollFlag = 50;
                    gtmEventObj.eventLabel = '50%';
                    setGtmEvent();
                } else if (
                    scrollPercentRounded >= 75 &&
                    scrollPercentRounded <= 85 &&
                    scrollFlag !== 75
                ) {
                    scrollFlag = 75;
                    gtmEventObj.eventLabel = '75%';
                    setGtmEvent();
                } else if (
                    scrollPercentRounded >= 100 &&
                    scrollPercentRounded <= 110 &&
                    scrollFlag !== 100
                ) {
                    scrollFlag = 100;
                    gtmEventObj.eventLabel = '100%';
                    setGtmEvent();
                }
            });

            $('#use-case-search-submit').click(function () {
                gtmEventObj.eventCategory = eventCategory.search;
                gtmEventObj.eventAction = 'usecase';
                gtmEventObj.eventLabel = encodeURIComponent(
                    $('#use-case-search').val()
                );
                setGtmEvent();
            });
            $('#moreUseCase').click(function () {
                gtmEventObj.eventCategory = eventCategory.use_case;
                gtmEventObj.eventAction = 'view_more';
                gtmEventObj.eventLabel = eventCategory.use_case;
                setGtmEvent();
            });
            $('#morePublications').click(function () {
                gtmEventObj.eventCategory = eventCategory.publication;
                gtmEventObj.eventAction = 'view_more';
                gtmEventObj.eventLabel = eventCategory.publication;
                setGtmEvent();
            });
            $('#moreNews').click(function () {
                gtmEventObj.eventCategory = eventCategory.news;
                gtmEventObj.eventAction = 'view_more';
                gtmEventObj.eventLabel = eventCategory.news;
                setGtmEvent();
            });
            $('#news-search-submit').click(function () {
                gtmEventObj.eventCategory = eventCategory.search;
                gtmEventObj.eventAction = 'news';
                gtmEventObj.eventLabel = encodeURIComponent(
                    $('#news-search').val()
                );
                setGtmEvent();
            });
            $('.gtmArticleLink').click(function () {
                gtmEventObj.eventCategory = eventCategory.news;
                gtmEventObj.eventAction = 'read_intention';
                gtmEventObj.eventLabel = $(this).data('title');
                setGtmEvent();
            });
            $('.gtmPublicationLink').click(function () {
                gtmEventObj.eventCategory = eventCategory.publication;
                gtmEventObj.eventAction = 'read_intention';
                gtmEventObj.eventLabel = $(this).data('title');
                setGtmEvent();
            });
            $('.gtmUseCaseLink').click(function () {
                gtmEventObj.eventCategory = eventCategory.use_case;
                gtmEventObj.eventAction = 'read_intention';
                gtmEventObj.eventLabel = $(this).data('title');
                setGtmEvent();
            });
            $('.gtmContactLink').click(function () {
                gtmEventObj.eventCategory = eventCategory.contact;
                gtmEventObj.eventAction = 'contact_intention';
                gtmEventObj.eventLabel = $(this).data('title');
                setGtmEvent();
            });
            $('.gtmArticleCategory').click(function () {
                gtmEventObj.eventCategory = eventCategory.news;
                gtmEventObj.eventAction = 'article_filter';
                gtmEventObj.eventLabel = $(this).data('title');
                setGtmEvent();
            });
            $('#publication-search-submit').click(function () {
                gtmEventObj.eventCategory = eventCategory.search;
                gtmEventObj.eventAction = 'publication';
                gtmEventObj.eventLabel = encodeURIComponent(
                    $('#news-search').val()
                );
                setGtmEvent();
            });
            $('.gtmPublicationCategory').click(function () {
                gtmEventObj.eventCategory = eventCategory.publication;
                gtmEventObj.eventAction = 'publication_filter';
                gtmEventObj.eventLabel = $(this).data('title');
                setGtmEvent();
            });
            $('.gtmFooterContactsButton').click(function () {
                gtmEventObj.eventCategory = eventCategory.footer;
                gtmEventObj.eventAction = 'country_selection';
                gtmEventObj.eventLabel = $(this).data('title');
                setGtmEvent();
            });
            $('.jobList__apply').click(function () {
                gtmEventObj.eventCategory = eventCategory.apply;
                gtmEventObj.eventAction = 'apply_intention';
                gtmEventObj.eventLabel = $(this).data('title');
                setGtmEvent();
            });
            $('.gtmFooterContactsTel').click(function () {
                gtmEventObj.eventCategory = eventCategory.footer;
                gtmEventObj.eventAction = 'call_intention';
                gtmEventObj.eventLabel = $(this).html();
                setGtmEvent();
            });
            $('.gtmFooterContactsEmail').click(function () {
                gtmEventObj.eventCategory = eventCategory.footer;
                gtmEventObj.eventAction = 'mail_intention';
                gtmEventObj.eventLabel = $(this).html();
                setGtmEvent();
            });
            $('.gtmFooterMenu .menu-item a').click(function () {
                gtmEventObj.eventCategory = eventCategory.footer;
                gtmEventObj.eventAction = 'menu';
                let rel = String($(this).attr('rel'));
                let href = $(this).attr('href');
                gtmEventObj.eventLabel =
                    rel === 'external'
                        ? href + '|' + $(this).html()
                        : $(this).html();
                setGtmEvent();
            });
            $('.gtmFooterImageMenu').click(function () {
                gtmEventObj.eventCategory = eventCategory.footer;
                gtmEventObj.eventAction = 'menu';
                let rel = String($(this).attr('rel'));
                if (rel === 'external') {
                    let href = $(this).attr('href');
                    gtmEventObj.eventLabel = href + '|' + $(this).data('title');
                } else {
                    gtmEventObj.eventLabel = $(this).data('title');
                }
                setGtmEvent();
            });
            $('.gtmBurgerButton').click(function () {
                gtmEventObj.eventCategory = eventCategory.header;
                gtmEventObj.eventAction = 'menu';
                gtmEventObj.eventLabel = $(this)
                    .parents('.mainHeader')
                    .hasClass('mainHeader--active')
                    ? 'open'
                    : 'close';
                setGtmEvent();
            });
            $('.gtmHeaderMenu .menu-item a').click(function () {
                gtmEventObj.eventCategory = eventCategory.header;
                gtmEventObj.eventAction = 'menu';
                gtmEventObj.eventLabel = $(this).html();
                setGtmEvent();
            });
            $('.gtmHeaderSocialMedia .menu-item a').click(function () {
                gtmEventObj.eventCategory = eventCategory.social;
                gtmEventObj.eventAction = 'burger';
                gtmEventObj.eventLabel = findSocialMedia($(this));
                setGtmEvent();
            });
            $('.gtmFooterSocialMedia .menu-item a').click(function () {
                gtmEventObj.eventCategory = eventCategory.social;
                gtmEventObj.eventAction = 'footer';
                gtmEventObj.eventLabel = findSocialMedia($(this));
                setGtmEvent();
            });
            $('.gtmUSocialMedia .menu-item a').click(function () {
                gtmEventObj.eventCategory = eventCategory.footer;
                gtmEventObj.eventAction = 'social';
                gtmEventObj.eventLabel = findSocialMedia($(this));
                setGtmEvent();
            });
            $('.gtmAuthorSocialMedia').click(function () {
                gtmEventObj.eventCategory = eventCategory.social;
                gtmEventObj.eventAction = 'external';
                gtmEventObj.eventLabel = findSocialMedia($(this));
                setGtmEvent();
            });
            $('.dpsp-network-btn').click(function (e) {
                gtmEventObj.eventCategory = eventCategory.social;
                gtmEventObj.eventAction = 'sidebar';
                gtmEventObj.eventLabel = findSocialMedia($(this));
                setGtmEvent();
            });
            $('.gtmFeaturedArticleReadMore').click(function (e) {
                gtmEventObj.eventCategory = eventCategory.news;
                gtmEventObj.eventAction = 'view_more';
                gtmEventObj.eventLabel = 'news';
                setGtmEvent();
            });
            $('.gtmFeaturedPublicationReadMore').click(function (e) {
                gtmEventObj.eventCategory = eventCategory.publication;
                gtmEventObj.eventAction = 'view_more';
                gtmEventObj.eventLabel = 'news';
                setGtmEvent();
            });
            $('.gtmServices').click(function (e) {
                gtmEventObj.eventCategory = eventCategory.services;
                gtmEventObj.eventAction = 'discover';
                gtmEventObj.eventLabel = $(this).data('title');
                setGtmEvent();
            });
            $('.gtmBreadcrumb a').click(function () {
                gtmEventObj.eventCategory = eventCategory.navigation;
                gtmEventObj.eventAction = 'breadcrumb';
                gtmEventObj.eventLabel = $(this).html();
                setGtmEvent();
            });
            $('.gtmAboutUs a').click(function () {
                gtmEventObj.eventCategory = eventCategory.about_us;
                gtmEventObj.eventAction = 'job_offer';
                gtmEventObj.eventLabel = $(this).html();
                setGtmEvent();
            });
            $('.gtmDownloadPdf').click(function () {
                gtmEventObj.eventCategory = eventCategory.formation;
                gtmEventObj.eventAction = 'download_file';
                gtmEventObj.eventLabel = $(this).data('title');
                setGtmEvent();
            });
            $('.gtmVideoSelect').click(function () {
                gtmEventObj.eventCategory = eventCategory.player;
                gtmEventObj.eventAction = 'choose_video';
                gtmEventObj.eventLabel = $(this).data('video_id');
                setGtmEvent();
            });
            $('.gtmBackToHome').click(function () {
                gtmEventObj.eventCategory = eventCategory.navigation;
                gtmEventObj.eventAction = 'home';
                gtmEventObj.eventLabel = $(this).data('title');
                setGtmEvent();
            });
        });
    }
}
