import hljs from 'highlight.js';
import AbstractComponent from './abstractComponent';

export default class HighlightCode extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);
        if (this.exists) {
            document
                .querySelectorAll('div.highlight__code pre code')
                .forEach((el) => {
                    hljs.highlightElement(el);
                });
        }
    }
}
