import AbstractComponent from './abstractComponent';

export default class ExpertiseList extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            $('.expertiseSliderContent__list').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                dots: false,
                infinite: true,
                adaptiveHeight: true,
                asNavFor: '.expertiseSliderNav__list',
                responsive: [
                    {
                        breakpoint: 900,
                        settings: {
                            arrows: true,
                            dots: true,
                        },
                    },
                ],
            });
            $('.expertiseSliderNav__list').slick({
                vertical: true,
                arrows: false,
                verticalSwiping: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                asNavFor: '.expertiseSliderContent__list',
                infinite: true,
                centerMode: false,
                focusOnSelect: true,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 1,
                            arrows: false,
                            dots: false,
                        },
                    },
                ],
            });

            $('.expertise-list-0').children('button').addClass('active');

            $('.expertiseSliderDesktop .expertiseSliderNav__link').on('click', function () {
                $('.expertiseSliderDesktop button').removeClass('active');
                $(this).addClass('active');

                const buttonClass = $(this).parent().attr('class');

                $('.expertiseSliderContent__items').hide();
                $(`#${buttonClass}`).show();
            });
        }
    }
}
