import AbstractComponent from './abstractComponent';
import 'slick-carousel';

export default class Testimonials extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            this.totalElement = this.jQuerySection.find('.testimonial').length;
            this.nav = this.jQuerySection.find('.testimonials__nav');

            let maxHeight = -1;
            let maxHeightLink = -1;
            this.jQuerySection.find('.testimonial').each(function () {
                if (jQuery(this).height() > maxHeight) {
                    maxHeight = jQuery(this).height();
                }
            });
            this.jQuerySection.find('.testimonial').each(function () {
                if (jQuery(this).height() < maxHeight) {
                    jQuery(this).css('height', maxHeight);
                }
            });

            this.jQuerySection.find('.testimonials__link').each(function () {
                if (jQuery(this).height() > maxHeightLink) {
                    maxHeightLink = jQuery(this).height();
                }
            });
            this.jQuerySection.find('.testimonials__link').each(function () {
                if (jQuery(this).height() < maxHeightLink) {
                    jQuery(this).css('height', maxHeightLink);
                }
            });

            this.slickSettings = {
                dots: false,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                touchMove: false,
                draggable: false,
                vertical: true,
                infinite: true,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            vertical: false,
                            centerMode: true,
                        },
                    },
                ],
            };

            this.slickNavSettings = {
                dots: false,
                arrows: false,
                slidesToShow: 5,
                draggable: false,
                slidesToScroll: 1,
                centerMode: true,
                touchMove: false,
                vertical: true,
                infinite: true,
                asNavFor: this.jQuerySection.find('.testimonials__main'),
                focusOnSelect: true,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            vertical: false,
                        },
                    },
                ],
            };

            this.jQuerySection
                .find('.testimonials__main')
                .not('.slick-initialized')
                .slick(this.slickSettings);

            this.nav.not('.slick-initialized').slick(this.slickNavSettings);
        }
    }
}
