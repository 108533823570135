import AbstractComponent from './abstractComponent';

export default class VideoGallery extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);
        if (this.exists) {
            $('.videoGallery__videoList li button').on('click', function () {
                $('#main_video_iframe').attr(
                    'src',
                    `https://www.youtube.com/embed/${$(this).attr(
                        'data-videoId'
                    )}?loop=1&autoplay=0`
                );
                const index = $(this).attr('data-IndexId');
                $('.videos__icon--play').each((i, obj) => {
                    jQuery(obj).toggle(i !== index - 1);
                });
                $('.videos__icon--pause').each((i, obj) => {
                    jQuery(obj).toggle(i === index - 1);
                });

                $('.videoGallery__videoLinks').toggle();
                $('.videoClose-icon').toggleClass('open');
            });
            $('.toggle__video').on('click', () => {
                $('.videoGallery__videoLinks').toggle();
                $('.videoClose-icon').toggleClass('open');
            });
        }
    }
}
