import AbstractComponent from './abstractComponent';

export default class JobForm extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            const form = this.jQuerySection.find('.jobForm__form');
            const firstName = this.jQuerySection.find('.jobForm__firstName');
            const lastName = this.jQuerySection.find('.jobForm__lastName');
            const email = this.jQuerySection.find('.jobForm__email');
            const file = this.jQuerySection.find('.jobForm__file');
            const gdpr = this.jQuerySection.find('.jobForm__consent');
            const error = this.jQuerySection.find('.form__Error');
            const message = this.jQuerySection.find('.jobForm__message');
            const emailPattern = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;

            form.submit(() => {
                let flag = true;
                error.html('');
                if (!email.val()) {
                    email
                        .siblings('.form__Error')
                        .html(Translator.trans('form.error.job_email'));
                    flag = false;
                } else if (!emailPattern.test(email.val())) {
                    email
                        .siblings('.form__Error')
                        .html(Translator.trans('form.error.invalid_job_email'));
                    flag = false;
                }

                if (!gdpr.length) {
                    if (!firstName.val()) {
                        firstName
                            .siblings('.form__Error')
                            .html(
                                Translator.trans('form.error.job_first_name')
                            );
                        flag = false;
                    }
                    if (!lastName.val()) {
                        lastName
                            .siblings('.form__Error')
                            .html(Translator.trans('form.error.job_last_name'));
                        flag = false;
                    }
                    if (!file.val()) {
                        file.siblings('.form__Error').html(
                            Translator.trans('form.error.job_file')
                        );
                        flag = false;
                    }
                }
                if (!flag) {
                    gtmEvent({
                        eventCategory: 'apply',
                        eventAction: 'error_form',
                        eventLabel: 'field_error',
                    });
                }
                return flag;
            });

            this.jQuerySection
                .find('.jobForm__input, .jobForm__textarea')
                .on('focusout', function () {
                    if ($(this).val() !== '') {
                        $(this).addClass('has-content');
                    } else {
                        $(this).removeClass('has-content');
                    }
                });

            this.jQuerySection
                .find('.jobForm__input, .jobForm__textarea')
                .on('focus', () => {
                    message.hide();
                });

            this.jQuerySection.find('#job_resume').on('change', function () {
                const filename = this.files[0].name;
                document.getElementById('fileName').innerHTML = filename;
            });
        }
    }
}
