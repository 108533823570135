import 'is-in-viewport';
import AbstractComponent from './abstractComponent';

export default class UseCase extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            jQuery(window).scroll(() => {
                jQuery(
                    '.inview:in-viewport, .usecaseDetail__content h2:in-viewport, .usecaseDetail__content p:in-viewport'
                ).addClass('animate-in');
            });
        }
    }
}
