import AbstractComponent from './abstractComponent';
import 'jquery-ui/themes/base/selectmenu.css';
import 'jquery-ui/ui/widgets/selectmenu';

export default class ContactForm extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        function enableSubmitButton(inputs, recipient, submit) {
            let index;
            let emptyFlag = false;
            for (index = 0; index < inputs.length; index += 1) {
                const element = $(inputs[index]);
                if (!element.val()) {
                    emptyFlag = true;
                }
            }

            if (recipient.length && !recipient.val()) {
                emptyFlag = true;
            }
            submit.prop('disabled', emptyFlag);
        }
        if ($('.contactForm__info__message').length) {
            $('html, body').animate(
                {
                    scrollTop: $('.contactForm__info__message').offset().top,
                },
                1000
            );
        }
        if (this.exists) {
            const form = this.jQuerySection.find('.contactForm__form');
            const name = this.jQuerySection.find('.contactForm__name');
            const email = this.jQuerySection.find('.contactForm__email');
            const phone = this.jQuerySection.find('.contactForm__phone');
            const company = this.jQuerySection.find('.contactForm__company');
            const recipient = this.jQuerySection.find(
                '.contactForm__recipient'
            );
            const need = this.jQuerySection.find('.contactForm__need');
            const gdpr = this.jQuerySection.find('.contactForm__gdpr');
            const field = this.jQuerySection.find('.contactForm__input');
            const submit = this.jQuerySection.find('.btn-contact');
            const inputs = this.jQuerySection.find('.contactForm__fields');
            const error = this.jQuerySection.find('.form__Error');
            const phonePattern =
                /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
            const emailPattern = /^([\w-.]+@([\w-]+\.)+[\w-]{2,20})?$/;

            inputs.keyup(() => {
                enableSubmitButton(inputs, recipient, submit);
            });

            form.submit(() => {
                let flag = true;
                error.html('');
                if (!email.val()) {
                    email
                        .siblings('.form__Error')
                        .html(Translator.trans('form.error.contact_email'));
                    flag = false;
                } else if (!emailPattern.test(email.val())) {
                    email
                        .siblings('.form__Error')
                        .html(
                            Translator.trans('form.error.invalid_contact_email')
                        );
                    flag = false;
                }
                if (!need.val()) {
                    need.siblings('.form__Error').html(
                        Translator.trans('form.error.contact_need')
                    );
                    flag = false;
                }
                if (recipient.length && !recipient.val()) {
                    recipient
                        .siblings('.form__Error')
                        .html(Translator.trans('form.error.recipient'));
                    flag = false;
                }
                if (!gdpr.length) {
                    if (!name.val()) {
                        name.siblings('.form__Error').html(
                            Translator.trans('form.error.contact_name')
                        );
                        flag = false;
                    }
                    if (!phone.val()) {
                        phone
                            .siblings('.form__Error')
                            .html(
                                Translator.trans(
                                    'form.error.invalid_contact_phone'
                                )
                            );
                        flag = false;
                    } else if (!phonePattern.test(phone.val())) {
                        phone
                            .siblings('.form__Error')
                            .html(
                                Translator.trans(
                                    'form.error.invalid_contact_invalid_phone'
                                )
                            );
                        flag = false;
                    }
                    if (!company.val()) {
                        company
                            .siblings('.form__Error')
                            .html(
                                Translator.trans(
                                    'form.error.invalid_contact_company'
                                )
                            );
                        flag = false;
                    }
                }
                if (!flag) {
                    gtmEvent({
                        event: 'click',
                        eventCategory: 'contact',
                        eventAction: 'error_form',
                        eventLabel: 'field_error',
                    });
                }
                return flag;
            });

            field.focus(function () {
                jQuery(this).siblings('.form__Error').html('');
            });

            $('.contactForm__input, .contactForm__textarea').focusout(
                function () {
                    if ($(this).val() !== '') {
                        $(this).addClass('has-content');
                    } else {
                        $(this).removeClass('has-content');
                    }
                }
            );

            if ($('.contactForm__info__message').length > 0) {
                setTimeout(() => {
                    $('.contactForm__info__message').fadeOut();
                }, 3000);
            }

            this.jQuerySection.find('.contactForm__recipient').selectmenu();

            this.jQuerySection
                .find('.contactForm__recipient')
                // eslint-disable-next-line no-unused-vars
                .on('selectmenuselect', function (event, ui) {
                    enableSubmitButton(inputs, recipient, submit);
                    if ($(this).val() !== '') {
                        $(this)
                            .parent()
                            .children('.ui-selectmenu-button')
                            .addClass('has-content');
                    } else {
                        $(this)
                            .parent()
                            .children('.ui-selectmenu-button')
                            .removeClass('has-content');
                    }
                });
        }
    }
}
