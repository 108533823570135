import AbstractComponent from './abstractComponent';

export default class WebinarArchive extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            $('#moreWebinar').click(function () {
                const moreButton = $(this);
                const waitMessage = $('.newsSearch__wait');
                const nextPage = moreButton.data('next-page');

                const path = moreButton.data('path');
                const limit = 6;
                $.ajax({
                    data: { search: '', page: nextPage, limit },
                    url: path,
                    dataType: 'json',
                    beforeSend () {
                        moreButton.hide();
                        waitMessage.show();
                    },
                    success (result) {
                        waitMessage.hide();
                        if (result) {
                            $('.webinarArchiveArticles__list').append(
                                result.html
                            );
                            moreButton.data('next-page', result.page);
                            if (result.nextCount > 0) {
                                moreButton.show();
                            }
                        }
                    },
                });
            });
        }
    }
}
