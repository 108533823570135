export default class AbstractComponent {
    constructor(globals, selector) {
        this.selector = selector;
        this.$window = globals.$window;
        this.$document = globals.$document;
        this.$body = globals.$body;
        this.$section = null;

        if (typeof selector === 'string') {
            this.$section = this.$document.getElementsByClassName(selector);
        }

        this.jQuerySection = jQuery(selector);

        this.breakpoints = {
            MOBILE: 'mobile',
        };

        this.orientations = {
            LANDSCAPE: 'landscape',
            PORTRAIT: 'portrait',
        };
    }

    get exists() {
        return this.selector;
    }

    get scrollTop() {
        if (!this.$document) return 0;
        return (
            this.$document.body.scrollTop ||
            this.$document.documentElement.scrollTop
        );
    }

    get windowDimensions() {
        return {
            width:
                this.$window.innerWidth ||
                this.$document.documentElement.clientWidth ||
                this.$document.body.clientWidth,
            height:
                this.$window.innerHeight ||
                this.$document.documentElement.clientHeight ||
                this.$document.body.clientHeight,
        };
    }

    isBreakpointMatched(bp) {
        if (bp.toLowerCase() === this.breakpoints.MOBILE) {
            return this.$window.matchMedia('(max-width: 991px)').matches;
        }

        return false;
    }

    isOrientationMatched(mode) {
        switch (mode) {
            case this.orientations.LANDSCAPE:
                return this.$window.matchMedia('(orientation: landscape)')
                    .matches;
            case this.orientations.PORTRAIT:
                return this.$window.matchMedia('(orientation: portrait)')
                    .matches;
            default:
                return false;
        }
    }

    /**
     * Init a component asynchrounousely
     *
     * @returns This
     * @memberof AbstractComponent
     */
    init() {
        if (this.exists && this.addEvents) {
            this.addEvents();
        }

        return null;
    }
}
