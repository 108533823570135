import AbstractComponent from './abstractComponent';

export default class ErrorPage extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            this.typeTextBlock = this.jQuerySection.find('.errorPage__preCode');
            const homepageUrl = this.jQuerySection.find(
                '.errorPage__hiddenUrl'
            )[0].innerHTML;
            this.cursor = 0;
            this.speed = 50;
            this.typeText = `<!DOCTYPE html>
  <html lang='en'>
  <head>
    <title>ekino - Error 404 : Not Found</title>
  </head>
  <body>
    <h1>Error 404 - Page not found</h1>
    <p>Sorry, the page you requested could not be found.<br>
    Return to the <a href='${homepageUrl}'>home page</a>.</p>
  </body>
</html>`;

            this.typeWriter();
        }
    }

    typeWriter() {
        if (this.cursor < this.typeText.length) {
            this.typeTextBlock.append(this.typeText.charAt(this.cursor));
            this.cursor += 1;
            setTimeout(this.typeWriter.bind(this), this.speed);
        }
    }
}
