import AbstractComponent from './abstractComponent';

export default class Header extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            const highlightBanner = $('.highlightBanner');
            const timerStart = Date.now();
            window.onload = function () {
                const timeLoaded = Date.now() - timerStart;
                if (timeLoaded < 500) {
                    setTimeout(() => {
                        jQuery(document.body).addClass('pageReady');
                    }, 500);
                } else {
                    jQuery(document.body).addClass('pageReady');
                }
            };

            const globalSelect = document.querySelectorAll(
                '.globalSwitch__select'
            );

            globalSelect.forEach((link) => {
                link.addEventListener('focus', function () {
                    this.parentElement.classList.add('focus');
                });
            });

            const globalList = document.querySelectorAll('.globalSwitch__list');

            globalList.forEach((sublink) => {
                const globalLink = Array.from(
                    sublink.querySelectorAll('.globalSwitch__link')
                );
                const lastGlobalLink = globalLink.pop();
                lastGlobalLink.addEventListener('blur', () => {
                    sublink.parentElement.classList.remove('focus');
                });
            });
            $(() => {
                $('.burgerBtn').click(() => {
                    $('.mainHeader').toggleClass('mainHeader--active');
                    $('body').toggleClass('overflow-hidden');
                });
            });

            const $menu = $('.burgerNav__list .menu-item-has-children');
            $(document).mouseup((e) => {
                if (!$menu.is(e.target) && $menu.has(e.target).length === 0) {
                    $menu.removeClass('--openSubMenu');
                }
            });
            $(document).keyup((e) => {
                if (e.keyCode === 27) {
                    $menu.removeClass('--openSubMenu');
                }
            });
            $('.burgerNav__list .menu-item-has-children')
                .children('a')
                .on('click', function (event) {
                    event.preventDefault();
                    $(this)
                        .parent()
                        .toggleClass('--openSubMenu')
                        .siblings()
                        .removeClass('--openSubMenu');
                });

            $(window).scroll(function () {
                if ($(this).scrollTop() > 50) {
                    $('body').addClass('scrollDown');
                } else {
                    $('body').removeClass('scrollDown');
                }
            });

            if (highlightBanner) {
                $('.homeBanner__fixed').css({
                    position: 'relative',
                });
            }

            $('.highlightBanner__close').on('click', function () {
                $(this).parent().hide();
                $('.homeBanner__fixed').css({
                    position: 'fixed',
                });
            });
        }
    }
}
