import AbstractComponent from './abstractComponent';
import 'jquery-ui/themes/base/selectmenu.css';
import 'jquery-ui/ui/widgets/selectmenu';

export default class PartnersFilter extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            let optionText = "<option value=''>Select category</option>";
            $('.partner_heading_section').each(function () {
                optionText += `<option value=${$(this).attr(
                    'data-heading'
                )}>${$(this).text()}</option>`;
            });
            $('#partnersFilter__selectWrapper').html(optionText);
            $('#partnersFilter__selectWrapper').on('change', () => {
                if ($('#partnersFilter__selectWrapper').val() !== '') {
                    $('.partner-page-filter-section').hide();
                    $(
                        `.partner-page-${$(
                            '#partnersFilter__selectWrapper'
                        ).val()}`
                    ).show();
                } else {
                    $('.partner-page-filter-section').show();
                }
            });

            $('#partnersFilter__selectWrapper').selectmenu({
                open(/* event, ui */) {
                    $('#partnersFilter__selectWrapper option[value=""]').text(
                        Translator.trans(
                            'our_partern.partern_placeholder_change'
                        )
                    );
                    $('#partnersFilter__selectWrapper').selectmenu('refresh');
                },
                close(/* event, ui */) {
                    $('#partnersFilter__selectWrapper option[value=""]').text(
                        Translator.trans('our_partern.partern_placeholder')
                    );
                    $('#partnersFilter__selectWrapper').selectmenu('refresh');
                },
                change(/* event, ui */) {
                    if ($('#partnersFilter__selectWrapper').val() !== '') {
                        $('.partner-page-filter-section').hide();
                        $(
                            `.partner-page-${$(
                                '#partnersFilter__selectWrapper'
                            ).val()}`
                        ).show();
                    } else {
                        $('.partner-page-filter-section').show();
                    }
                },
            });
        }
    }
}
