import AbstractComponent from './abstractComponent';
import 'jquery-ui/themes/base/selectmenu.css';
import 'jquery-ui/ui/widgets/selectmenu';

export default class JobList extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        function setHeadings() {
            let index;
            let flag = false;
            const selectionHeading = $('.jobList__heading');
            $('.jobList__empty').hide();
            for (index = 0; index < selectionHeading.length; index += 1) {
                const heading = $(selectionHeading[index]);
                if (
                    heading
                        .siblings('.jobList__list_element')
                        .hasClass('jobList__show')
                ) {
                    heading.parent('.jobList__list').show();
                    flag = true;
                } else {
                    heading.parent('.jobList__list').hide();
                }
            }
            if (!flag) {
                $('.jobList__empty').show();
            }
        }
        function replaceSpecialChars(key) {
            const listChars = {
                à: 'a',
                â: 'a',
                é: 'e',
                è: 'e',
                ê: 'e',
                Ï: 'i',
            };
            const exp = new RegExp(Object.keys(listChars).join('|'), 'gi');
            return key.replace(exp, (x) => listChars[x.toLowerCase()]);
        }
        function filter(selectClass, elementData, hideSearch) {
            const select = $(selectClass);
            const inputSearch = $('.jobList__searchWrapper_search_searchInput');
            if (hideSearch) {
                inputSearch.val('');
            }
            const selectValue = select
                .find('option:selected')
                .val()
                .toUpperCase();

            const list = $('.jobList__content');
            const elements = list.find('.jobList__list_element');

            let index;
            let elementNextData = 'location';
            let selectNextClass = '.jobList__selectWrapper_locationFilter';
            if (elementData === 'location') {
                elementNextData = 'department';
                selectNextClass = '.jobList__selectWrapper_departmentFilter';
            }
            const selectNextValue = $(selectNextClass)
                .find('option:selected')
                .val()
                .toUpperCase();

            for (index = 0; index < elements.length; index += 1) {
                const element = $(elements[index]);
                const textValue = element.data(elementData).toUpperCase();
                const textNextValue = element
                    .data(elementNextData)
                    .toUpperCase();

                if (
                    textValue.indexOf(selectValue) > -1 &&
                    textNextValue.indexOf(selectNextValue) > -1
                ) {
                    element
                        .addClass('jobList__show')
                        .removeClass('jobList__hide');
                } else {
                    element
                        .addClass('jobList__hide')
                        .removeClass('jobList__show');
                }
            }
            setHeadings();
        }

        function search() {
            const list = $('.jobList__content');
            const elements = list.find('.jobList__list_element');
            const inputSearch = $('.jobList__searchWrapper_search_searchInput');
            let searchValue = replaceSpecialChars(inputSearch.val());
            searchValue = searchValue.toUpperCase();

            const locationValue = $('.jobList__selectWrapper_locationFilter')
                .find('option:selected')
                .val()
                .toUpperCase();

            const departmentValue = $(
                '.jobList__selectWrapper_departmentFilter'
            )
                .find('option:selected')
                .val()
                .toUpperCase();

            const departmentHead = list.find('.jobList__heading');

            if (inputSearch.val().length < 2) {
                if (locationValue.length <= 0 && departmentValue.length <= 0) {
                    elements
                        .addClass('jobList__show')
                        .removeClass('jobList__hide');
                    setHeadings();
                } else {
                    filter(
                        '.jobList__selectWrapper_locationFilter',
                        'location',
                        false
                    );
                }
                return;
            }

            let index;

            for (index = 0; index < elements.length; index += 1) {
                let flag = true;
                const element = $(elements[index]);
                const textValue = element.data('title').toUpperCase();
                const locationTextValue = element
                    .data('location')
                    .toUpperCase();
                const departmentTextValue = element
                    .data('department')
                    .toUpperCase();
                if (textValue.indexOf(searchValue) > -1) {
                    if (
                        locationValue.length > 0 &&
                        locationTextValue.indexOf(locationValue) <= -1
                    ) {
                        flag = false;
                    }
                    if (
                        departmentValue.length > 0 &&
                        departmentTextValue.indexOf(departmentValue) <= -1
                    ) {
                        flag = false;
                    }
                } else {
                    flag = false;
                }

                if (flag) {
                    element
                        .addClass('jobList__show')
                        .removeClass('jobList__hide');
                } else {
                    element
                        .addClass('jobList__hide')
                        .removeClass('jobList__show');
                }
            }

            for (let index2 = 0; index2 < departmentHead.length; index2 += 1) {
                const heading = $(departmentHead[index]);
                const headValue = heading.data('title').toUpperCase();
                if (locationValue.length > 0 || departmentValue.length > 0) {
                    return;
                }
                if (headValue.indexOf(searchValue) > -1) {
                    heading
                        .siblings('.jobList__list_element')
                        .addClass('jobList__show')
                        .removeClass('jobList__hide');
                }
            }

            setHeadings();
        }

        if (this.exists) {
            this.jQuerySection
                .find('.jobList__searchWrapper_search_searchInput')
                .on('keyup', () => {
                    search();
                    gtmEvent({
                        event: 'click',
                        eventCategory: 'search',
                        eventAction: 'career',
                        eventLabel: encodeURIComponent(
                            $(
                                '#jobList__searchWrapper_search_searchInput'
                            ).val()
                        ),
                    });
                });

            this.jQuerySection
                .find('.jobList__selectWrapper_locationFilter')
                // eslint-disable-next-line no-unused-vars
                .on('selectmenuselect', (event, ui) => {
                    filter(
                        '.jobList__selectWrapper_locationFilter',
                        'location',
                        true
                    );
                    gtmEvent({
                        event: 'click',
                        eventCategory: 'filter',
                        eventAction: 'geolocation_filter',
                        eventLabel: encodeURIComponent(
                            $('#jobList__selectWrapper_locationFilter').val()
                        ),
                    });
                });

            this.jQuerySection
                .find('.jobList__selectWrapper_departmentFilter')
                // eslint-disable-next-line no-unused-vars
                .on('selectmenuselect', (event, ui) => {
                    filter(
                        '.jobList__selectWrapper_departmentFilter',
                        'department',
                        true
                    );
                    gtmEvent({
                        event: 'click',
                        eventCategory: 'career',
                        eventAction: 'job_filter',
                        eventLabel: encodeURIComponent(
                            $('#jobList__selectWrapper_departmentFilter').val()
                        ),
                    });
                });

            this.jQuerySection
                .find('.jobList__selectWrapper_locationFilter')
                .on('selectmenuselect', function (/* event, ui */) {
                    $(this)
                        .parent()
                        .find('.ui-selectmenu-button')
                        .addClass('ui-selectmenu-bold');
                });

            this.jQuerySection
                .find('.jobList__selectWrapper_departmentFilter')
                .on('selectmenuselect', function (/* event, ui */) {
                    $(this)
                        .parent()
                        .find('.ui-selectmenu-button')
                        .addClass('ui-selectmenu-bold');
                });

            this.jQuerySection
                .find('.jobList__selectWrapper_locationFilter')
                .selectmenu({
                    open(/* event, ui */) {
                        $(
                            '#jobList__selectWrapper_locationFilter option[value=""]'
                        ).text(
                            Translator.trans('text.location_placeholder_change')
                        );
                        $('#jobList__selectWrapper_locationFilter').selectmenu(
                            'refresh'
                        );
                    },
                    close(/* event, ui */) {
                        $(
                            '#jobList__selectWrapper_locationFilter option[value=""]'
                        ).text(Translator.trans('text.location'));
                        $('#jobList__selectWrapper_locationFilter').selectmenu(
                            'refresh'
                        );
                    },
                });

            this.jQuerySection
                .find('.jobList__selectWrapper_departmentFilter')
                .selectmenu({
                    open(/* event, ui */) {
                        $(
                            '#jobList__selectWrapper_departmentFilter option[value=""]'
                        ).text(
                            Translator.trans(
                                'text.department_placeholder_change'
                            )
                        );
                        $(
                            '#jobList__selectWrapper_departmentFilter'
                        ).selectmenu('refresh');
                    },
                    close(/* event, ui */) {
                        $(
                            '#jobList__selectWrapper_departmentFilter option[value=""]'
                        ).text(Translator.trans('text.department'));
                        $(
                            '#jobList__selectWrapper_departmentFilter'
                        ).selectmenu('refresh');
                    },
                });

            $('#jobList__clear').click(() => {
                $('#jobList__searchWrapper_search_searchInput').val('');
                $('.jobList__list').show();
                $('.jobList__list').children().addClass('jobList__show');
                $('.jobList__list').children().removeClass('jobList__hide');
            });
        }
    }
}
