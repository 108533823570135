import AbstractComponent from './abstractComponent';
import 'jquery-ui/themes/base/autocomplete.css';
import 'jquery-ui/ui/widgets/autocomplete';

export default class NewsArchive extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            const cache = {};
            $('#news-search').autocomplete({
                minLength: 2,
                source(request, response) {
                    const { term } = request;
                    if (term in cache) {
                        response(cache[term]);
                        return;
                    }
                    request.type = $('#news-type').val();
                    $.getJSON('/api/v1/news/autocomplete', request, (data) => {
                        cache[term] = data;
                        response(data);
                    });
                },
            });

            $('#moreNews').click(function () {
                const moreButton = $(this);
                const waitMessage = $('.newsSearch__wait');
                const nextPage = moreButton.data('next-page');
                const search = $('#news-search').val();
                const type = $('#news-type').val();
                const path = moreButton.data('path');
                const limit = 6;
                $.ajax({
                    url: path,
                    data: {
                        type,
                        search,
                        page: nextPage,
                        limit,
                    },
                    dataType: 'json',
                    beforeSend () {
                        moreButton.hide();
                        waitMessage.show();
                    },
                    success (result) {
                        waitMessage.hide();
                        if (result) {
                            $('.newsArchiveArticles__list').append(result.html);
                            moreButton.data('next-page', result.page);
                            if (result.nextCount > 0) {
                                moreButton.show();
                            }
                        }
                    },
                });
            });
        }
    }
}
