import AbstractComponent from './abstractComponent';

export default class PartnersPage extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            $('#morePartners').click(function () {
                $(this).hide();
                $('.partnersPage__list').addClass('partnersPage__list--more');
            });

            if (window.location.hash) window.scroll(0, 0);
            setTimeout(() => {
                window.scroll(0, 0);
            }, 1);
            if (window.location.hash) {
                $('html,body').animate(
                    {
                        scrollTop: $(window.location.hash).offset().top - 100,
                    },
                    1000,
                    'swing'
                );
            }
        }
    }
}
